import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Branding from '../../shared/components/UIElements/EventBranding';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Feature from '../components/Feature';
import ItemList from '../components/ItemsList';

import './EventHome.css';


const EventHome = () => {

  const pageVariants = {
    initial: {
      opacity: 0,
      duration: 5,
      x:-200
    },
    in: {
      opacity: 1,
      duration: 2,
      x: 0
    },
    out: {
      opacity: 0,
      duration: 5,
      x:-200
    },
  }

  const { REACT_APP_API_URL } = process.env;
  const history = useHistory();
  const { email, userId, isLoggedIn, token } = useContext(AuthContext);
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const [featuredItem, setFeaturedItem] = useState([]);
  const [items, setItems] = useState();


  useEffect(() => {
    if (isLoggedIn) {
      //console.log(email);
      //console.log(userId);
      clearError()

      const fetchItems = async () => {
        try {
          const responseData = await sendRequest(
            `${REACT_APP_API_URL}/api/event-item/all`,
            'POST',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              }
          );
          if(responseData.eventItems) {
            setItems(responseData.eventItems)
            //console.log(responseData.eventItems);
            const featured = responseData.eventItems.filter(f => f.featured === true);
            //console.log(featured);
            if (featured.length > 0) {
              setFeaturedItem(featured);
            }

          }
         
        } catch (err) {
          //console.log(err);
        }
      };
      fetchItems();

    } else {
      //console.log("Not authorised");
      history.push("/auth");
    }
  }, [isLoggedIn, email, userId]);

  const itemSelectedHandler = (item) => {
    //console.log(item);
  }

  return (
    <div 
      className="page-wrapper"  initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
    <Branding src='https://eventstreams.co/img/HANSA/event-logo.png' alt='client name' eventTitle='Clinical case studies with imlifidase in kidney transplantation'/>
      {featuredItem.length > 0 && <Feature item={featuredItem[0]} />}
      {isLoading && <LoadingSpinner />}
      {items && <ItemList categoryTitle='Agenda' items={items} selectedItem={itemSelectedHandler} />}
    </div>
  );
};

export default EventHome;
