import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './Auth.css';
import EventBranding from '../../shared/components/UIElements/EventBranding';
import MoreInfo from '../../shared/components/UIElements/MoreInfo';

const ForgotPassword = () => {
  const { REACT_APP_PUBLIC_URL } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [requestSent, setRequestSent] = useState(false);

  const pageVariants = {
    initial: {
      opacity: 0,
     
    },
    in: {
      opacity: 1,
      duration:3,
     
    },
    out: {
      opacity: 0,
      duration:3,
      
    },
  }

  const submitHandler = async event => {
  event.preventDefault();

    try {
      const res = await sendRequest(
        `${REACT_APP_API_URL}/api/user/register-ticket`,
        'PATCH',
        JSON.stringify({
          email: event.target.email.value,
        }),
        {
          'Content-Type': 'application/json',
        }
      );
        setRequestSent(true);
        //console.log(res.success);
     
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
    
      <motion.div className="wrapper center col" initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}>
        <div className="auth-wrapper">
        <EventBranding src='https://eventstreams.co/img/HANSA/event-logo.png' alt='client name' eventTitle='Clinical case studies with imlifidase in kidney transplantation'/>
          {!requestSent ? (
            <div className="login-card" style={{ width: '100%' }}>
              <h2 className='ticket-request-title'>Ticket Request</h2>
              <p className='ticket-notice'>Your email address must have already been registered to claim your ticket number.</p>
              <MoreInfo />
              
              {isLoading ? <div className='center'><LoadingSpinner asOverlay /> </div>:
              <form className='forgot-form__wrapper' onSubmit={submitHandler}>
                
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email"
                  inputClassName="no-group form-padding"
                  
                />

                <Button
                  type="submit"
                  className="button-primary login-btn"
                  title="Register"
                />
              </form>

              }

              <NavLink className="forgot-link" to="/auth">
                Back to login
              </NavLink>
            </div>
          ) : (
            <div className="forgot-card">
              <h1>Ticket Requested</h1>

              <p className="reset-sent-notice">
                If your email is registered we have sent you your uniquie ticket number. If you dont see this right away check your junk box. 
              </p>
              <NavLink className="forgot-link" to="/auth">
                Back to login
              </NavLink>
            </div>
          )}
          <div className="request-message" >
            <div className="request-message-text">
              
            </div>
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default ForgotPassword;
