import React from 'react';
import './MainFooter.css';

const MainFooter = () => {
return (
    <footer>
    <div className="main-footer__shell">
       
        <div className="main-footer__bottom">
            <p>for techincal support contact: <a href="mailto:support@eventstreams.co?subject=Tech Support Request, HANS June 2021">support@eventstreams.co</a></p>
        </div>

    </div>
    </footer>

)};

export default MainFooter;