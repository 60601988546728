import React from 'react';


import './Item.css';

const Item = ({item, selected}) => {

    return (
        <div id='item'>
            <div className='thumb-container'>
                <img src={item.thumbUrl} alt={item.title} />
            </div>
            <div className='item-preview-content'>
                <h1 className='item-preview-title'>{item.title}</h1>
                <h2 className='item-preview-location'>{item.subTitle}</h2>
                <p className='item-preview-date'>{item.time} (CET)</p>      
            </div>
           

        </div>
    );
}

export default Item;