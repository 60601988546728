import React, {Fragment, useState} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import './VideoPlayer.css';

const VimeoPlayer = ({videoId, time}) => {

    const { sendRequest } = useHttpClient();
    const { REACT_APP_API_URL } = process.env;
    const [loadingVideo, setLoadingVideo] = useState(true)

    const videoOnLoad = async (id) => {
        //console.log('video loaded');
        setLoadingVideo(false);
        try {
           await sendRequest(
              `${REACT_APP_API_URL}/api/event-item/updateloadcount`,
              'POST',
              JSON.stringify({
                videoId: id
              }),
              {
                'Content-Type': 'application/json'
              }
            );
          } catch (err) {
            //console.log(err);
          }
      }
      
      const videoEnded = async (id) => {
        //console.log('video ended');
        try {
            await sendRequest(
               `${REACT_APP_API_URL}/api/event-item/updateendcount`,
               'POST',
               JSON.stringify({
                 videoId: id
               }),
               {
                 'Content-Type': 'application/json'
               }
             );
           } catch (err) {
             //console.log(err);
           }
      }

    return (
        <Fragment>
        {loadingVideo && 
            <div className='center'><h1 className='video-loading'>Loading...</h1></div>
        }
        <Vimeo 
        video={videoId} 
        responsive={true} 
        onTimeUpdate={time}  
        onLoaded={()=>videoOnLoad(videoId)} 
        onEnd={()=>videoEnded(videoId)}   
        />    
        </Fragment>
    )
}

export default VimeoPlayer;