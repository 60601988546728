import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import Branding from '../UIElements/Branding';
import TopLinks from './TopLinks';
import MobileNav from './MobileNav';
import './MainHeader.css';

const MainHeader = props => {

  return (
    <Fragment>
   
      <header className="header-container">
     
        <div className="header-container__shell">
          <div className="header-conatiner__top">
            <div className='branding-header-container'>
              <Branding src='https://eventstreams.co/img/HANSA/HANSA_WHITE.svg' height='50px' width='100px'/>
              <TopLinks />
            </div>
             
            </div>
        </div>
       

       
      </header>
    </Fragment>
  );
};

export default MainHeader;
