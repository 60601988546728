import React, {useState} from 'react';

import './Feature.css';
import FeatureHeader from './FeatureHeader';
import TotalDuration from '../../shared/components/UIElements/TotalDuration';
import VimeoPlayer from '../../shared/components/Video/VimeoPlayer';

const Feature = ({ item }) => {

  const [duration, setDuration] = useState('0 mins')
  const [current, setCurrent] = useState(0)


  const time = (event) => {
    //console.log(event);

    if (event.duration && event.duration > 0) {
      const totalDuration = (event.duration / 60)
      setDuration(`${Math.floor(totalDuration)} mins`)
    }

    const currentPosition = (event.percent * 100)    
    setCurrent(`${Math.floor(currentPosition)} %`)
    
  }

  return (
    <div id="feature">
      <div id="video">
        <div className="video-container">
          {item.videoId ? <VimeoPlayer videoId={item.videoId} time={time} /> : <div className='featured-not-found'>Video Not found</div>}
         
        </div>
      </div>
      <div id="info">
        <FeatureHeader
          title={item.title}
          subTitle={item.subTitle}
          date={item.date}
          location={item.location}
        />
        
        <div className="info-footer">
          <TotalDuration title='Duration' duration={duration} />
          <TotalDuration title='Progress' duration={current} />
        
        </div>
      </div>
    </div>
  );
};

export default Feature;
