import React, { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

import Auth from './auth/pages/Auth';
import RequestPassword from './auth/pages/RequestPassword';
import SetPassword from './auth/pages/SetPassword';
import EventItem from './eventItem/pages/EventItem';
import EventHome from './EventHome/pages/EventHome';

import Header from './shared/components/Header/MainHeader';
import Footer from './shared/components/Footer/MainFooter';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';

library.add(
  fab,
  faSignOutAlt
);

function App() {

  const { userId, email, token, login, logout } = useAuth();
  
  let routes;

  token
    ? (routes = (
        <AnimatePresence>
          <Switch>
            <Route path="/" exact>
              <EventHome />
            </Route>
            <Route path="/:category/:itemName">
              <EventItem />
            </Route>
            <Route path="*">
            <Redirect to="/" />
            </Route>
          </Switch>
          </AnimatePresence>
      ))
    : (routes = (
      <AnimatePresence>
          <Switch>
          <Route path="/" exact>
              <Redirect to="/auth" />
            </Route>
            <Route path="/auth" exact>
              <Auth />
            </Route>
            <Route path="/register-ticket">
              <RequestPassword />
            </Route>
            <Route path="/reset/:token">
              <SetPassword />
            </Route>
            <Route path="*">
              <Redirect to="/auth" />
            </Route>
          </Switch>
        </AnimatePresence>
      ));

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <Header />
        <main>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
        <Footer />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
