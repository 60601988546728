import React from 'react';

import './Time.css';

const Time = ({time}) => {
  return (
    <div className='time-container'>
    <p>{time} (CET)</p>
</div>
  );
};

export default Time;
