import React from 'react';

import './EventBranding.css';

const EventBranding = props => {

    return (
        <div className={`ev-branding ${props.className}`} style={props.style}>
        <img
          src={props.src}
          alt={props.alt}
          style={{ width: props.width, height: props.height }}
        />
        <h1>{props.eventTitle}</h1>
      </div>
    );
};


export default EventBranding;