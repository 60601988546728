import React from 'react';

import './Branding.css';

const Branding = props => {

    return (
        <div className={`branding ${props.className}`} style={props.style}>
        <img
          src={props.src}
          alt={props.alt}
          style={{ width: props.width, height: props.height }}
        />
       
      </div>
    );
};


export default Branding;