import React, {useState, useEffect, useContext} from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext} from '../../context/auth-context';

import './TopLinks.css'; 


const TopLinks = () => {

    const auth = useContext(AuthContext)

    return (
        <React.Fragment>

            {auth.isLoggedIn && (
                     <div className="top-links top-links__logout" onClick={auth.logout}> 
                         <FontAwesomeIcon icon="sign-out-alt" />
                    </div>
               
            )}
    
    </React.Fragment>
    );
};

export default TopLinks;