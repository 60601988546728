import React from 'react';

import './TotalDuration.css';

const TotalDuration = ({title, duration}) => {
  return (
    <div className='duration-container'>
    <p>{title}:</p>
    <p className='duration-data'>{duration}</p>
</div>
  );
};

export default TotalDuration;
