import React from 'react';
import './MoreInfo.css';

const MoreInfo = () => {
    return (
        <div className='more-info-container'>
            <p className='more-info-text'>For more information please contact: </p>
            <a  className='more-info-link' href="mailto:commsis-hansastandalone@cellohealth.com?subject=Mail from Our Site">commsis-hansastandalone@cellohealth.com</a>
      </div>
    );
  };

  export default MoreInfo;