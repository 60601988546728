
import VimeoPlayer from '../../shared/components/Video/VimeoPlayer';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ItemList from '../../EventHome/components/ItemsList';
import Branding from '../../shared/components/UIElements/EventBranding';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import TotalDuration from '../../shared/components/UIElements/TotalDuration';
import Time from '../../shared/components/UIElements/Time';

import './EventItem.css';


const EventItem = props => {

  const { REACT_APP_API_URL } = process.env;
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState();
  const [item, setItem] = useState();
  const { category } = useParams();
  const [cat, setCat] = useState();
  const [videoId, setVideoId] = useState();
  const [duration, setDuration] = useState(0)
  const [current, setCurrent] = useState(`0 %`)

  const { isLoading, sendRequest } = useHttpClient();
  const { token, isLoggedIn } = useContext(AuthContext);


  useEffect(() => {
      category && //console.log(category);
      category && setCat(category.replaceAll("-", " "))
      if (location.state) {
      location.state.item && setItem(location.state.item); 
      location.state.item && setVideoId(location.state.item.videoId); 
      location.state.item && setDuration(`${location.state.item.duration / 60} mins`);
    } 
  }, [location, category]);

  useEffect(() => {
    if (isLoggedIn) {
    const fetchItems = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/event-item/all`,
          'POST',
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
        );
        if(responseData.eventItems) {
          setItems(responseData.eventItems)
          //console.log(responseData.eventItems);
        }
       
      } catch (err) {
        //console.log(err);
      }
    };
    fetchItems();
  } else {
    history.push("/auth");
  }
    
  }, [])

  const time = (event) => {
    //console.log(event);

    const totalDuration = (event.duration / 60)
    const currentPosition = (event.percent * 100)

    setDuration(`${Math.floor(totalDuration)} mins`)
    setCurrent(`${Math.floor(currentPosition)} %`)
    
  }

  const pageVariants = {
    initial: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
    in: {
      opacity: 1,
      duration: 2,
      x: 0,
    },
    out: {
      opacity: 0,
      duration: 5,
      x: -200,
    },
  };

  const selectedItem = item => {
      setItem(item);
  }

  return (
    <div
      className="page-wrapper"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
    <div id='event-item'>
    <Branding src='https://eventstreams.co/img/HANSA/event-logo.png' alt='client name' eventTitle='Clinical case studies with imlifidase in kidney transplantation'/>
      
      {item ? 
      <div className='event-item-video-container'>
        <div className="item-video">
            
            {videoId ? <VimeoPlayer videoId={item.videoId} time={time} /> : <div>Video Not found</div> }
          
        </div>
      </div>
      :
      <div className='center'><h1>Video not found</h1></div>
      }

      {item && <div className='event-item-content-body'>
          <div className='event-item-content-info'>
                <h1 className='item-title'>{item.title}</h1>
                <h2 className='item-location'>{item.subTitle}</h2>
               
                <div className='video-info-container'>
                  <Time time={item.time}/>
                  <TotalDuration title='Duration' duration={duration} />
                  <TotalDuration title='Progress' duration={current} />
                </div>
          </div>
      </div>
    }
    {cat && items && !isLoading && <ItemList categoryTitle={'Agenda'} items={items} selectedItem={selectedItem} />}
    {isLoading && <div className='center'><LoadingSpinner /></div>}
      </div>
     
    </div>
  );
};

export default EventItem;
