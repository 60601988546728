import React from 'react';

import './FeatureHeader.css'

const FeatureHeader = ({title, subTitle, date, location}) => {

    return (
        <div className='feature-header'>
            <h1 className='feature-title'>{title}</h1>
            <h2 className='feature-sub-title'>{subTitle}</h2>
            <p className='feature-location'>{date}</p>
        </div>
    );
}

export default FeatureHeader;